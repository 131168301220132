<template>
  <page-container
    is-show-breadcrumbs
    style="padding-bottom: 24px"
    class="custom-el-style"
  >
    <specification>
      <template #singleline> 自定义数据模板，是根据自身需求，自定义工业互联网入网信息的字段，用于在标识管理中手动添加标识时，作为新增标识的信息录入模板</template>
    </specification>
    <div class="filter-box">
      <el-form inline :model="filterCondition" size="medium" label-width="76px">
        <el-form-item label="模板名称：">
          <el-input
            v-model="filterCondition.keyword"
            placeholder="请输入模板名称"
            style="width: 210px"
          />
        </el-form-item>
        <el-form-item style="margin-left: 5px">
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-button type="primary" @click="handleOpen"> 创建数据模板 </el-button>
      </el-form>
    </div>
    <el-table
      :header-cell-style="{
        height: '60px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#909399',
        background: '#FAFAFA',
      }"
      :data="listData" style="margin-top: 16px; font-size: 14px"
    >
      <!-- <el-table-column
        style="padding-left: 16px"
        prop="tpl_id"
        label="序号"
        min-width="300"
      /> -->
      <el-table-column
        style="padding-left: 16px"
        prop="tpl_name"
        label="模板名称"
        min-width="300"
      />
      <el-table-column min-width="120" width="250" align="left" label="操作">
        <template slot-scope="scope">
          <el-button
            style="padding: 10px 16px 10px 0"
            type="text"
            @click="handleEdit(scope.row.tpl_id)"
          >
            查看详情
          </el-button>
          <el-button
            style="padding: 10px 16px 10px 0"
            type="text"
            @click="handleCopy(scope.row)"
          >
            复制
          </el-button>
          <el-button
            style="padding: 10px 16px 10px 0"
            type="text"
            class="red_color"
            @click="handleDelete(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <pagination
        :page-index="filterCondition.pageIndex"
        :page-size="filterCondition.pageSize"
        :total="total"
        @sizechange="handleSizeChange"
        @currentchange="handleCurrentChange"
        style="margin-top: 24px"
      />
    </el-row>
    <el-dialog title="查看详情" width="30%" center :visible.sync="isShowDialog">
      <el-form :model="formData">
        <el-form-item label="企业前缀：" :label-width="formLabelWidth">
          {{ formData.tpl_node }}
        </el-form-item>
        <el-form-item label="行业门类：" :label-width="formLabelWidth">
          {{ formData.INDUSTRY_CATEGORY }}
        </el-form-item>
        <el-form-item label="行业大类：" :label-width="formLabelWidth">
          {{ formData.INDUSTRY_SPECIFIC }}
        </el-form-item>
        <el-form-item label="行业中类：" :label-width="formLabelWidth">
          <span>{{ trade }}</span>
        </el-form-item>
        <el-form-item label="行业小类：" :label-width="formLabelWidth">
          <span>{{ subclass }}</span>
        </el-form-item>
        <el-form-item label="模板名称：" :label-width="formLabelWidth">
          {{ formData.tpl_name }}
        </el-form-item>
        <el-form-item
          label="模板内容："
          v-if="items.length > 0"
          :label-width="formLabelWidth"
        />
        <div v-for="(item, index) in items" :key="index">
          <el-form-item
            :label="item.attr_name"
            :label-width="formLabelWidth"
            prop="attr_name"
          >
            <el-input style="width: 200px; margin-left: 10px" disabled /><span>{{ item.attr_required ? "*必填" : "" }}</span>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import Specification from "@/components/common/Specification.vue";
import { companyApi, templateApi } from "@/services/api";
export default {
  components: {
    PageContainer,
    Specification
  },
  data() {
    return {
      trade: "",
      subclass: "",
      formData: {},
      isShowDialog: false,
      formLabelWidth: "120px",
      filterCondition: {
        pageIndex: 1,
        pageSize: 10,
        keyword: ""
      },
      codeList: [],
      total: 0,
      listData: [],
      items: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let { dataList, pageInfo } = await templateApi.list(this.filterCondition);
      this.listData = dataList;
      this.total = pageInfo.total;
    },
    handleSizeChange(val) {
      this.filterCondition.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.filterCondition.pageIndex = val;
      this.getList();
    },
    handleSearch() {
      this.filterCondition.pageIndex = 1;
      this.getList();
    },
    handleOpen() {
      this.$router.push("/manage/patternplate/add");
    },
    addCateory() {},
    async handleEdit(id) {
      let state = this.$store.state;
      let { data } = await templateApi.detail({ id });
      console.log(data);
      this.formData = {
        tpl_name: data.tpl_name,
        tpl_specific: data.tpl_specific,
        tpl_trade: data.tpl_trade,
        tpl_subclass: data.tpl_subclass
      };
      this.items = data.items;
      this.formData.tpl_node = state.company_node;
      this.formData.INDUSTRY_CATEGORY = state.entBasicInfo.industryCategory;
      this.formData.INDUSTRY_SPECIFIC = state.entBasicInfo.industrySpecific;
      this.$nextTick(() => {
        this.getCategory(this.formData.tpl_specific);
      });
      this.isShowDialog = true;
    },
    async getCategory(code) {
      let { data } = await companyApi.category({
        dict_key: "INDUSTRY_CATEGORY",
        code,
        is_child: true
      });
      console.log(data);
      if (this.formData.tpl_trade) {
        let tradeData = data.filter(
          item => item.dict_code == this.formData.tpl_trade
        );
        let subclassData = tradeData[0].children.filter(
          item => item.dict_code == this.formData.tpl_subclass
        );
        this.trade = tradeData[0].dict_value;
        this.subclass = subclassData[0].dict_value;
      }
      console.log(this.formData.trade);
      console.log(this.formData.subclass);
    },
    handleCopy(row) {
      this.$router.push(`/manage/patternplate/add?tpl_id=${row.tpl_id}`);
    },
    handleDelete(row) {
      let ids = [];
      ids.push(row.tpl_id);
      console.log(ids);
      this.$confirm("此操作将永久删除该模板, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        let { code, message } = await templateApi.delete({ ids });
        if (code == 200) {
          this.getList();
          this.$message({
            type: "success",
            message
          });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.filter-box {
  padding: 24px 20px 6px;
  background: var(--table-header-background);
  /deep/.el-table__body {
    font-size: 14px !important;
  }
  .el-button + .el-button {
    padding: 0;
  }
}
/deep/.el-table__cell .cell {
  padding-left: 16px !important;
}
.table-state {
  .iconfont {
    cursor: pointer;
    margin-left: 8px;
    font-size: 16px;
    color: #7b7e8c;
  }
}
.el-form {
  /deep/ .el-form-item {
    .el-form-item__label {
      padding-right: 0;
    }
  }
  /deep/.el-select .el-input {
    width: auto !important;
  }
  /deep/ .el-button.el-button--primary {
    min-width: 80px;
  }
}
/deep/ .el-dialog {
  .el-dialog__header {
    border-radius: 4px;
  }
  .el-dialog__body {
    padding: 14px 24px;
  }
  .el-dialog__footer {
    padding-right: 24px;
    border-radius: 4px;
    padding-bottom: 24px;
    .el-button {
      width: 60px;
      height: 32px;
      padding: 8px 0;
      text-align: center;
    }
  }
}
/deep/.el-input--mini .el-input__inner {
  height: 32px;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  height: 30px;
}
</style>
